import React from 'react'
import { Link } from 'react-router-dom';
import {MdKeyboardArrowRight} from '../../assets/icons/icons';
import { useSelector } from 'react-redux';
import {
    FaFacebookF,
    FaLinkedin,
    FaInstagram,
    FaTwitter
    } from "react-icons/fa";


export default function Footer() {

    const footerData = useSelector((state) => state.websiteReducer.data.data);
    return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className="py-[60px] px-0">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                            <div className="lg:col-span-4 md:col-span-12">
                                <Link to="/#" className="text-[22px] focus:outline-none">
                                    <img src={footerData ? footerData.site_footer_logo : ''} alt="footer_logo" className='width50'/>
                                </Link>


                            </div>

                            <div className="lg:col-span-2 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold ms-2">Social Links</h5>


                                { footerData ? footerData.home_sections.includes('social_link') && (
                                    <ul className="list-none mt-5 space-x-1 space-y-1">

                                        <li className="inline"><Link to="/" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="/" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="/" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="/" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter className='text-sm'/></Link></li>

                                    </ul>
                                ) : ''}
                            </div>

                            <div className="lg:col-span-3 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold ms-2">Useful Links</h5>
                                <ul className="list-none footer-list mt-6">
                                        <li><Link to='/page/about-us' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="text-xl me-1  " /> About Us </Link></li>
                                        <li><Link to='/category' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1  "/> Courses</Link></li>
                                        <li><Link to='/page/term-conditions' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1"/>Terms & Condition </Link></li>
                                        <li><Link to='/page/privacy-policy' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1"/> Privacy Policy</Link></li>

                                </ul>
                            </div>

                            <div className="lg:col-span-3 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold">Address</h5>
                                <p className="mt-6">{footerData ? footerData.site_address : ''}</p>

                                    <div className="grid grid-cols-1">
                                    <div className="my-3 mt-5">
                                                <label className="form-label">
                                                    <a href="mailto:icpshiksha@gmail.com">Email id - {footerData ? footerData.site_email : ''}</a>
                                                </label>

                                                <label className="form-label" >
                                                    <a href="tel:7015768079">Mobile No. - {footerData ? footerData.site_phone : ''}</a>
                                                </label>

                                            </div>

                                        {/* <div  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Subscribe</div> */}
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
            <div className="container relative text-center">
                <div className="grid md:grid-cols-2 items-center">
                    <div className="md:text-start text-center">
                        <p className="mb-0">© {new Date().getFullYear()} {footerData ? footerData.site_footer_text : ''}</p>
                    </div>


                </div>
            </div>
        </div>
    </footer>
    )
}
